import $ from 'jquery';
window.$ = $;
window.jQuery = $;
//import 'popper.js';
//import 'bootstrap';

$(function () {
    "use strict";

    // Berechnet die richtigen Viewport-Werte für mobile Ansicht
    // suehe https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // für dieses CSS:
    // height: 100vh;
    // height: calc(var(--vh, 1vh) * 100);
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // We listen to the resize event
    window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });


    //=================================== WEITERLEITUNG ZU URL PER ONCLICK-EVENT AUF DIV =====================================//
    $("li[data-href],div[data-href]").on("click tap", function () {
        if (typeof $(this).attr("data-target") !== "undefined") {
            window.open($(this).attr("data-href"), $(this).attr("data-target"));
        } else {
            window.location.href = $(this).attr("data-href");
        }
    });

    //=================================== Scroll to top =====================================//
    $('.scrollUpButton-JS').on("click tap", function () {
        var duration = 800;
        $('html, body').animate({
            scrollTop: 0
        }, duration);
        return false;
    });
    
    setTimeout(
  function() 
  {
    $("#modalDialogButton").addClass("slideUp");
  }, 1000);
    
    

});
